import { Injectable } from '@angular/core';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { filter, Observable, map, Subject, mergeMap } from 'rxjs';
import { FileLocationDetails } from 'src/app/core/models/file-upload.interface';
import {
	getSelectedLocation,
	getUserId,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { LocationList } from 'src/app/shared/models/locations';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Injectable({
	providedIn: 'root',
})
export class SurveyService {
	public surveyFormValue = new Subject<string>();
	private _locationId: number;
	private _rootLocationId: number;
	private _userId: number;
	public deleteobj: any;
	constructor(
		private apiService: BaseWebApiService,
		private sharedStore: Store<SharedState>,
		private localData: LocalDataService,
		private encryptionService: EncryptionService
	) {
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._locationId = res.location?.id;
				},
			});
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._rootLocationId = res.locationId;
				},
			});
		this.sharedStore
			.pipe(
				select(getUserId),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => (this._userId = res),
			});
	}

	public getPresignedUrl(path: string): Observable<FileLocationDetails> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				return this.apiService
					.post(`${UrlNames.v2myCourses}${UrlNames.takeCourse}`, {
						path: this.encryptionService.encrypt(path, key),
						duration: this.encryptionService.encrypt('', key),
					})
					.pipe(map((res: FileLocationDetails) => res));
			})
		);
	}

	public getLocationsList(root: boolean = false): Observable<LocationList> {
		return this.apiService
			.get(`${UrlNames.locations}`, {
				userId: this.localData.getUserId(),
				isLiveEvents: false,
				locationId: root ? this._rootLocationId : this._locationId,
			})
			.pipe(
				map((x: LocationList) => {
					return {
						...x,
						userList: this.localData.sortStrings(
							x.userList,
							'firstName'
						),
					};
				})
			);
	}

	public createQuestion(payload: any): Observable<any> {
		return this.apiService.post(`${UrlNames.createQuestion}`, payload);
	}
	public editOverView(incomingData: any): Observable<any> {
		let data = JSON.stringify(incomingData.editDataArray);
		let payload = JSON.parse(data);
		return this.apiService.post(
			`${UrlNames.editOverView}/${incomingData.surveyId}?isInactiveSurvey=${incomingData.forInactiveSurvey}&locationId=${this._locationId}`,
			payload
		);
	}

	public getSurveyById(surveyId: string | number) {
		return this.apiService.get(`${UrlNames.survey}/${surveyId}`, {});
	}

	public delete(surveyIds) {
		let payLoad = JSON.parse(surveyIds);

		return this.apiService.delete(`${UrlNames.survey}`, payLoad);
	}

	public getSurveyByAssignId(surveyAssignmentId: string) {
		return this.apiService.get(
			`${UrlNames.surveyByAssignment}/${surveyAssignmentId}`,
			{
				userid: this._userId,
			}
		);
	}

	public getSurveys(filter: string, isSubSurvey: boolean = false) {
		return this.apiService.get(`${UrlNames.survey}`, {
			filter: filter,
			isSubSurvey: isSubSurvey,
			locationId: this._locationId,
			userId: this._userId,
		});
	}

	public getLibraryPendingSurveys(
		filter: string,
		isSubSurvey: boolean = false
	) {
		return this.apiService.get(
			`${UrlNames.getSurveysForSurveyLibraryAndPending}`,
			{
				filter: filter,
				isSubSurvey: isSubSurvey,
				locationId: this._locationId,
				userId: this._userId,
			}
		);
	}

	public getSurveysCategories() {
		return this.apiService.get(`${UrlNames.getSurveyCategories}`, {
			locationId: this._locationId,
		});
	}

	public getSurveysByCategory(categoryId) {
		return this.apiService.get(
			`${UrlNames.getSurveyCategories}/${categoryId}/survey`,
			{
				locationId: this._locationId,
				userId: this._userId,
			}
		);
	}

	public getFilteredSurveys(status: string) {
		return this.apiService.get(`${UrlNames.survey}`, {
			filter: status,
			locationId: this._locationId,
			userId: this._userId,
		});
	}

	public createSurvey(testPayload: any): Observable<any> {
		return this.apiService.post(`${UrlNames.survey}`, testPayload);
	}

	public editSurvey(testPayload: any): Observable<any> {
		return this.apiService.put(
			`${UrlNames.survey}/${testPayload.surveyId}`,
			testPayload
		);
	}

	public importQuestionSurvey(testPayload: any): Observable<any> {
		return this.apiService.post(
			`${UrlNames.importQuestions}/${testPayload.surveySectionId}/questions`,
			testPayload
		);
	}

	public addGroup(payload: any): Observable<any> {
		return this.apiService.post(`${UrlNames.addGroup}`, payload);
	}

	public deleteGroup(groupId: number): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.deleteGroup}/${groupId}`,
			null,
			null,
			null,
			{
				userid: this._userId,
			}
		);
	}

	public addMembersToGroup(payload: any): Observable<any> {
		payload.locationId = this._locationId;
		return this.apiService.post(`${UrlNames.addMembersToGroup}`, payload, {
			userid: this._userId,
		});
	}

	public deleteMembers(memberId: number): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.deleteMember}/${memberId}`,
			null,
			null,
			null,
			{
				userid: this._userId,
			}
		);
	}

	public assignSurvey(assignPayload: any): Observable<any> {
		return this.apiService.post(`${UrlNames.assignSurvey}`, assignPayload);
	}

	public assignSurveyToExternal(assignPayload: any): Observable<any> {
		return this.apiService.post(
			`${UrlNames.assignSurveyToExternal}`,
			assignPayload
		);
	}

	public getAssignedSurvey(
		userId: number,
		fetchOpen: boolean
	): Observable<any> {
		return this.apiService.get(`${UrlNames.assinedSurvey}`, {
			userid: userId,
			fetchOpen: fetchOpen,
		});
	}

	public getSurveyOnDemand(): Observable<any> {
		return this.apiService.get(`${UrlNames.surveyOnDemand}`, {
			userid: this._userId,
			locationId: this._locationId,
		});
	}

	public getGroups() {
		return this.apiService.get(`${UrlNames.getGroups}`, {
			userid: this._userId,
			locationId: this._locationId,
		});
	}

	public getGroupMembers() {
		return this.apiService.get(`${UrlNames.getGroupMembers}`, {
			userid: this._userId,
			locationId: this._locationId,
		});
	}

	public getSurveyPreviewBySurveyId(id: number): Observable<any> {
		return this.apiService
			.get(`${UrlNames.getSurveyPreviewBySurveyId}/${id}`, undefined)
			.pipe(map((res) => res['data']));
	}

	public inActive(payload: any): Observable<any> {
		return this.apiService.put(`${UrlNames.inActivateSurvey}`, payload);
	}

	public active(payload: any): Observable<any> {
		return this.apiService.put(`${UrlNames.activateSurvey}`, payload);
	}

	public unAssign(payload: any): Observable<any> {
		return this.apiService.put(`${UrlNames.unassignSurvey}`, payload, {
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
		});
	}

	public publish(surveyId: number): Observable<any> {
		return this.apiService.put(`${UrlNames.publishSurvey}/${surveyId}`);
	}

	public retire(payload: any): Observable<any> {
		return this.apiService.put(`${UrlNames.retireSurvey}`, payload);
	}

	public saveUserSurveyResponse(payload: any): Observable<Object> {
		return this.apiService.post(
			`${UrlNames.saveUserSurveyResponse}`,
			payload,
			{
				userid: this._userId,
			}
		);
	}
	public getSurveyAnswers(userSurveyId: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.surveyReport}${UrlNames.viewAnswers}`,
			{ userSurveyId, locationId: this.localData.getLocationId() }
		);
	}

	public getSurveyAssignmentDetails(surveyId: number) {
		return this.apiService.get(
			`${UrlNames.surveyAssignmentDetails}/${surveyId}`,
			{
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public deleteSurveyAssignments(surveyIds: number[]) {
		return this.apiService.delete(
			`${UrlNames.deleteSurveyAssignments}`,
			surveyIds,
			false,
			[],
			{
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}

	public editSurveyAssignments(payload: any) {
		return this.apiService.put(
			`${UrlNames.editSurveyAssignments}`,
			payload,
			{
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
				assignmentId: payload.editAssignmentInstanceList[0],
			}
		);
	}

	public addSequenceSurvey(testPayload: any): Observable<any> {
		return this.apiService.put(
			`${UrlNames.survey}${UrlNames.sequencing}`,
			testPayload
		);
	}

	public editSequenceSurvey(testPayload: any): Observable<any> {
		return this.apiService.put(
			`${UrlNames.survey}${UrlNames.sequencing}`,
			testPayload
		);
	}

	public getSurveySequence(surveyId: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.survey}${UrlNames.sequencing}/${surveyId}`,
			null
		);
	}

	public editSurveyQuestion(question) {
		return this.apiService.post(
			`${UrlNames.survey}${UrlNames.question}`,
			question,
			{
				userid: this._userId,
			}
		);
	}
	passFormValue(data) {
		//passing the data as the next observable
		this.surveyFormValue.next(data);
	}
}
